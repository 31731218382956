<template>
  <div class="CookiePrivacyPolicy">
    <div class="Wrapper">
      <BaseH2 class="Title--white CookiePrivacyPolicy-Title">
        <template v-if="$route.name === 'CookiePolicy'">
          {{$t('cookiePolicy.title')}}
        </template>
        <template v-else>
          {{$t('privacyPolicy.title')}}
        </template>
      </BaseH2>
      <BaseSubtitle class="CookiePrivacyPolicy-Subtitle" :class="{'font-ComicSansMS': this.$i18n.locale === 'pl' || this.$i18n.locale === 'tr'}">
        <template v-if="$route.name === 'CookiePolicy'">
          {{$t('cookiePolicy.subtitle')}}
        </template>
        <template v-else>{{$t('privacyPolicy.subtitle')}}</template>
      </BaseSubtitle>
      <BaseSupport :support-info="$route.name === 'CookiePolicy' ? getCookiePolicy : getPrivacyPolicy"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CookiePrivacyPolicy",
  computed: {
    ...mapGetters(['getPrivacyPolicy', 'getCookiePolicy'])
  }
}
</script>

<style lang="scss">
.CookiePrivacyPolicy {
  padding: 103px 0 40px;

  @media (min-width: $screen-s) {
    padding: 140px 0 0;
  }

  @media (min-width: $screen-m) {
    padding: 140px 0 60px;
  }

  @media (min-width: $screen-l) {
    padding: 235px 0 80px;
  }

  &-Title {
    margin-bottom: 50px;
    text-align: center;
  }

  &-Subtitle {
    text-align: center;
    text-transform: uppercase;
  }

  &-Text {
    margin-bottom: 100px;
  }
}
</style>
